<template>
  <div>
    <div class="mb-2 py-2 px-2 card-plain">
      <div class="row" style="height: 100%">
        <div
          class="col-6 col-md-6 float-left"
          style="cursor: pointer"
          @click="teacherDetail(dataTeacher.id)"
        >
          <b-img
            class="rounded-circle"
            v-if="dataTeacher && dataTeacher.profile_image"
            :src="dataTeacher.profile_image"
            height="80px"
            width="80px"
          ></b-img>
          <b-img
            class="rounded-circle"
            v-else
            :src="noImage"
            height="80px"
            width="80px"
          ></b-img>
          <b-card-text>
            {{
              dataTeacher
                ? dataTeacher.first_name + " " + dataTeacher.last_name
                : ""
            }}
          </b-card-text>
        </div>
        <div class="col-6 col-md-6 text-left">
          <div>
    
            <span class="orange-text mr-2"> <i class="bi bi-star"></i></span>
            {{ lessonsAmount }}
            บทเรียน
          </div>
          <div>
            <span class="orange-text mr-2">
              <i class="bi bi-chat-text"></i
            ></span>
            {{ quizAmount }} แบบทดสอบ
          </div>
          <div>
            <img class="mr-1" :src="playCircle" alt="" />
            {{ totalTime }} นาที
          </div>
          <div v-if="courseStatus">
            <button
              v-if="!courseStatus.isBan"
              class="btn btn-block btn-secondary mt-3"
            >
              {{ courseTimeCalculateDisabled(course.enroll)?cardText(course.enroll):courseStatus.user_enroll_status.name }}
            </button>
            <button v-else disabled class="btn btn-block btn-secondary mt-3">
              ระงับการใช้งาน
            </button>
          </div>
          <div v-else>
              <div class="mt-3">
          </div>
          <!-- <div>courseTimeCalculateDisabled : {{ courseTimeCalculateDisabled(course.enroll) }}</div> -->
          <!-- <div>courseCheckApprove_rights : {{ courseCheckApprove_rights(course, course.approve_right) }}</div> -->
            <button v-if="courseCheckApprove_rights(course, course.approve_right)"
              :disabled="courseTimeCalculateDisabled(course.enroll) || courseCheckApprove_rights(course, course.approve_right)"
              class="btn btn-block main-orange-btn-outline mt-3"
            >
              <div v-if="courseTimeCalculateDisabled(course.enroll)">{{cardText(course.enroll)}}</div>
              <div v-else-if="course.paid_type === 'paid' && course.subject_payments_pending.length === 0">{{cardText(course.enroll)}}</div>
              <div v-else-if="course.paid_type === 'paid' && course.subject_payments_pending[0]?.status === 'reject'">{{cardText(course.enroll)}}</div>
              <div v-else-if="course.paid_type === 'paid' && course.subject_payments_pending[0]?.status === 'pending'">รอดำเนินการ</div>
              <div v-else>{{cardText(course.enroll)}}</div>
            </button>

            <button v-else
              :disabled="courseTimeCalculateDisabled(course.enroll) || courseCheckApprove_rights(course, course.approve_right)"
              class="btn btn-block main-orange-btn-outline mt-3"
            >
              <div v-if="courseTimeCalculateDisabled(course.enroll)">{{cardText(course.enroll)}}</div>
              <div @click="openModal(course.id)" v-else-if="course.paid_type === 'paid' && course.subject_payments_pending.length === 0">{{cardText(course.enroll)}}</div>
              <div @click="openModal(course.id)" v-else-if="course.paid_type === 'paid' && course.subject_payments_pending[0]?.status === 'reject'">{{cardText(course.enroll)}}</div>
              <div v-else-if="course.paid_type === 'paid' && course.subject_payments_pending[0]?.status === 'pending'">รอดำเนินการ</div>
              <div @click="register()" v-else>{{cardText(course.enroll)}}</div>
            </button>

          <b-modal content-class="qr-modal-size"  centered id="qr-modal" hide-header hide-footer >
              <div class="text-center pa-5">
                <div >
                <h2>สแกน QR Code</h2>
                <p>สแกน QR Code เพื่อชำระเงิน</p>
                <b-img :src="qrCode" width="200" height="200" fluid alt="QR Code"></b-img>
                <div class="mt-3">
                  <h5 class="d-flex justify-content-left">Course</h5>
                  <div class="d-flex justify-content-between">
                    <p class="text-truncate w-75 text-left">{{course.name}}</p>
                    <p>{{course.price}} ฿</p> 
                  </div>
                </div>
              <div class="d-flex flex-column justify-content-center">
                <b-button variant="secondary" @click="navigateToConfirm"  class="w-100 m-1 z-3">สแกนเสร็จสิ้น</b-button>
                <b-button @click="closeModal" style="background-color: white;color: gray;" class="w-100 m-1 z-3">ยกเลิก</b-button>
              </div>    
                </div>
                </div>
          </b-modal> 
          </div>

        </div>
      </div>
    </div>

  </div>
  
</template>
<script>
import Swal from "sweetalert2";
import noImage from "../assets/image/mock/no-image.png";
import img from "./../../public/temp.jpg";
import playCircle from "../assets/image/icon/PlayCircle.png";
import courses from "../service/api/coursesAPI";
import { mapState } from "vuex";
import moment from "moment";
import qrCode from "../assets/image/qr_code.jpg";

export default {
  name: "teacher",
  computed: {
    ...mapState({
      userData: (state) => state.Auth.userData,
      access_token: (state) => state.Auth.access_token,
      course: (state) => {
        return state.Courses.course;
      },
    }),
  },

  data() {
    return {
      qrCode :qrCode,
      playCircle: playCircle,
      // courseStatus: false,
      lessonsAmount: 0,
      quizAmount: 0,
      totalTime: 0,
      noImage: noImage,
      img: img,
      approve_rights: [],
      approve_rightsID: null,
    };
  },
  created() {
    
    this.countLesson();
    this.getapprove_rights();
    // this.checkCourseStatus();
  },
  props: {
    lessons: null,
    dataTeacher: null,
    courseStatus: null,
    // course: null,
  },
  methods: {
    async teacherDetail(id) {
      let path = `/teacher-detail`;
      await this.$router.push({
        path: path,
        query: { id: id },
      });
    },
    courseTimeCalculateDisabled(enrolls) {
      // console.log('course : ',this.course)
      if (!enrolls) {
        return true;
      } else {
        const enrollsActive = enrolls.filter((enroll) => enroll.status);
        if (enrollsActive.length <= 0) {
          return true;
        } else {
          const now = moment();
          let disabled = true;

          // enrollsActive.forEach((enroll) => {
            const enroll = enrolls[0];
            const start_at = moment(enroll.register_start);
            const end_at = moment(enroll.register_end);
            
            const course_start_at = moment(enroll.course_start);
            const course_end_at = moment(enroll.course_end);

            // if (now.isBetween(start_at, end_at) && now.isBetween(course_start_at, course_end_at)) {
            //   disabled = false;
            // }

        // console.log('this.approve_right : ',this.course.approve_right)
        // console.log('this.access_rights : ',this.course.access_rights)
        // console.log('this.course : ',this.course )
        // console.log('start_at : ',start_at )
        // console.log('end_at : ',end_at )
        // console.log('course_start_at : ',course_start_at )
        // console.log('course_end_at : ',course_end_at )
            if( (start_at && end_at) && (course_start_at && course_end_at) ){
              if(this.course.access_rights === 'public' || (this.course.access_rights === 'private' && this.course.approve_right !== null) ){
                if(enroll.user_enroll.length > 0 && now <= course_end_at){
                  disabled = false;
                }else if ( enroll.user_enroll.length == 0 && now < end_at  ) { 
                  disabled = false;
                } 
              }
            }
            
          // });
          return disabled;
        }
      }
    }
    ,cardText(enrolls){
      let text = 'ซื้อคอร์ส';
      if (!enrolls) {
      text = 'คอร์สเรียนสิ้นสุด';
      } else {
        const enroll = enrolls[0];
        let currentDate = moment();
        let registerStart = enroll?moment(enroll.register_start):false;
        let registerEnd = enroll?new moment(enroll.register_end):false;

        let courseStart = enroll?new moment(enroll.course_start):false;
        let courseEnd = enroll?new moment(enroll.course_end):false;
          
        // if( (registerStart && registerEnd) && (courseStart && courseEnd) ){
        //   if(enroll.user_enroll.length > 0 ){
        //     if( !(currentDate.isBetween(courseStart,courseEnd)) && currentDate > courseEnd ){
        //       text = 'คอร์สเรียนสิ้นสุด'
        //     }
        //   }else{
        //     if ( !(currentDate.isBetween(registerStart,registerEnd)) && currentDate > registerEnd ) { 
        //       text = 'ปิดรับสมัคร'
        //     } 
        //   }

        // }
        // console.log('this.approve_right : ',this.course.approve_right)
        // console.log('this.access_rights : ',this.course.access_rights)
        // console.log('this.course : ',this.course ) 
        // if( (this.course.access_rights === 'private' && this.course.approve_right !== null) || (this.course.access_rights === 'public') ){

          if ( !(currentDate.isBetween(registerStart,registerEnd)) && enroll.user_enroll.length === 0) { 
            text = 'ปิดรับสมัคร';
          }else if( !(currentDate.isBetween(courseStart,courseEnd)) && enroll.user_enroll.length > 0  ){
            text = 'คอร์สเรียนสิ้นสุด';
          }else if(this.course.paid_type !== "paid"){
            text = 'สมัครเรียน';
          } 
        // }else{
        //   if ( !(currentDate.isBetween(registerStart,registerEnd)) && enroll.user_enroll.length === 0) { 
        //     text = 'ปิดรับสมัคร';
        //   }else if( !(currentDate.isBetween(courseStart,courseEnd)) && enroll.user_enroll.length > 0  ){
        //     text = 'คอร์สเรียนสิ้นสุด';
        //   }else if(this.course.paid_type !== "paid"){
        //     text = 'สมัครเรียน';
        //   }
        // }

      }
      return text;
    }
    ,courseCheckApprove_rights(course, approve_rightsID) {
      if (course.access_rights == "public") {
        return false; // Disable approval rights for public courses
      } else {
        let disabled;
        if (!approve_rightsID) {
          disabled = true; // Disable approval rights if approve_rights is falsy
        } else {
          disabled = false; // Enable approval rights if approve_rights is truthy
        }
        return disabled;
      }
    },
    checkPaidType(course) {
      if (course.paid_type == "paid") {
        return true;
      } else {
        return false;
      }
    },

    inTimeDuration(startTimeString, endTimeString) {
      const startTime = moment(startTimeString);
      const endTime = moment(endTimeString);
      const now = moment();

      return now >= startTime && now <= endTime;
    },
    checkCourseStatus() {
      const model = {
        user_id: this.userData.id,
        subject_id: this.course.id,
        accessToken: this.access_token,
      };
      this.$store
        .dispatch("Courses/checkCourseStatus", model)
        .then((response) => {
          this.courseStatus = response;
        });
    },
    // async getapprove_rights() {
    //   this.approve_rights = await courses.getcCurseManage_Rights(this.userData.id, this.course.id);
    // },
    async getapprove_rights() {
      this.approve_rights = await courses.getcCurseManage_Rights(
        this.userData.id,
        this.course.id
      );
      if (this.approve_rights.result.manage_rights.length > 0) {
        this.approve_rightsID = this.approve_rights.result.manage_rights[0].id;
      }
    },
    countLesson() {
      const onlyLesson = this.lessons.filter((x) => x.type === "lesson");
      const onlyQuiz = this.lessons.filter((x) => x.type === "quiz");
      this.lessonsAmount = onlyLesson.length;
      this.quizAmount = onlyQuiz.length;
      this.totalTime = 0;
      onlyLesson.forEach((x) => {
        this.totalTime += x.lesson_minute;
      });
    },
    register() {
      if (!this.access_token) {
        this.$router.push("/login");
        return;
      }

      const enrollsActive = this.course.enroll.filter(
        (enroll) => enroll.status
      );
      
      // const currentDate = new Date();
      // const registerStart = new Date(enrollsActive[0].register_start);
      // const registerEnd = new Date(enrollsActive[0].register_end);

      // //validate open crouse date
      // if(!(currentDate >= registerStart && currentDate <= registerEnd)){
      //   Swal.fire("ขออภัย!", "คอร์ดเรียนนี้ได้ปิดรับสมัครแล้ว.", "error");
      //   return false;
      // }

      // //validate course is private & course is approved for this user register
      // if(this.course.access_rights!='public' || (this.course.approve_right == null && this.course.access_right === 'private')){
      //   Swal.fire("ขออภัย!", "คุณยังไม่ได้ถูกกำหนดสิทธิ์เข้าใช้งานคอร์ดเรียนนี้.", "error");
      //   return false;
      // }

      const now = moment();
      const enroll = enrollsActive.find((enroll) => {
        const start_at = moment(enroll.register_start);
        const end_at = moment(enroll.register_end);
        return now.isBetween(start_at, end_at);
      });

      Swal.fire({
        title: "ระบบกำลังทำงาน",
        html: "โปรดรอสักครู่...",
        allowEscapeKey: false,
        allowOutsideClick: false,
        didOpen: () => {
          Swal.showLoading();
          const model = {
            user_id: this.userData.id,
            subject_id: this.course.id,
            enroll_id: enroll.id,
          };
          this.$store
            .dispatch("Courses/registerCourse", {
              data: model,
              accessToken: this.access_token,
            })
            .then(() => {
              // this.checkCourseStatus();
              this.$emit("checkCourseStatus");
              Swal.clickConfirm();
            })
            .catch((err) => {
              if (err.response) {
                if (err.response.status == 401) {
                  this.$store.dispatch("Auth/logout").then(() => {
                    this.$router.push("/login");
                  });
                }
              }
              Swal.clickConfirm();
            });
        },
      });
    },
    openModal(id) {
      const enrollsActive = this.course.enroll.filter(
        (enroll) => enroll.status
      );
      const currentDate = new Date();
      const registerStart = new Date(enrollsActive[0].register_start);
      const registerEnd = new Date(enrollsActive[0].register_end);
      //validate open crouse date
      if(!(currentDate >= registerStart && currentDate <= registerEnd)){
        Swal.fire("ขออภัย!", "คอร์ดเรียนนี้ได้ปิดรับสมัครแล้ว.", "error");
        return false;
      }
      //validate course is private & course is approved for this user register
      if(this.course.approve_right == null && this.course.access_rights === 'private'){
        Swal.fire("ขออภัย!", "คุณยังไม่ได้ถูกกำหนดสิทธิ์เข้าใช้งานคอร์ดเรียนนี้.", "error");
        return false;
      }

      this.course_id = id
      this.$bvModal.show('qr-modal');
    },
    closeModal() {
      this.$bvModal.hide('qr-modal'); 
  },
  navigateToConfirm(){
    this.$router.push({ 
      name: "confirmPayment" ,
    params: { id: this.course_id }
    });
  },
  },
};
</script>
<style lang="scss">
.qr-modal-size {
  max-width: 400px; 
}
.text-truncate {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
</style>
