<template>
    <div class="fill-height">

      <TopNav />
    <Loading :loading="isLoading"></Loading>

      <div class="container my-5">
        <div class="d-flex  justify-content-center">        
            <div class="d-flex flex-column justify-content-center text-center">        
            <h2>ยืนยันการชำระเงิน</h2>
            <p>ยืนยันการชำระเงิน</p>
            <hr>
            </div>
        </div>
        <div style="background-color: white !important;padding: 12px;margin: 12px 0 ; border-radius: 12px; box-shadow: rgb(0 0 0 / 10%) 0px 0px 10px;" class="">

        <p class="font-weight-bold d-flex justify-content-center" style="font-size: large;">ระบบยืนยันการชำระเงิน</p>
        <div class="pa-2">
            <p class="font-weight-bold" >รายการชำระเงิน</p>
            <div class="d-flex  justify-content-between">
                <p class="d-flex justify-end" style="width:30%">{{ this.course.name }} :</p>
                <p class="ml-5" style="width:70%">{{ this.course.price }} บาท</p>
            </div>
        </div>
        <div class="py-2">
            </div>
        <div class="pa-2">
            <p class="font-weight-bold">ข้อมูลรายการคอร์ส</p>
            <div class="d-flex  justify-content-between">
                <div class="d-flex justify-end align-items-start" style="width: 30%; max-height: 300px;">
                    <img style="width: 100%; height: auto; object-fit: contain; max-width: 100%; max-height: 300px;" :src="course.banner_image" alt="">
                </div>
                <div class="ml-5" style="width:70%">
                    <p class="font-weight-bold">รายละเอียด</p>
                    <p v-html="this.course.description"></p>
                    <p class="font-weight-bold">จุดประสงค์</p>
                    <p v-html="this.course.objective"></p>
                

                </div>
            </div>
        </div>
            
        <div class="pa-2">
            <div class="d-flex  justify-content-between">
                <p class="d-flex justify-end" style="width:30%">ยอดชำระทั้งหมด :</p>
                <p class="ml-5" style="width:70%">{{ this.course.price }} บาท</p>
            </div>
        </div>

        <div class="pa-2">
            <div class="d-flex  justify-content-between">
                <p class="d-flex justify-end align-items-center" style="width:30%">วันที่โอนเงิน :</p>
                <div class="ml-5" style="width:70%">
                    <div class="" style="width:50%">
                        <v-menu
                            v-model="menu2"
                            :close-on-content-click="false"
                            :nudge-right="40"
                            transition="scale-transition"
                            offset-y
                            min-width="auto"
                        >
                        <template v-slot:activator="{ on, attrs }">
                          <v-text-field
                              v-model="formConfirmPayment.payment_date"
                              outlined
                              dense
                              append-icon="mdi-calendar"
                              readonly
                              v-bind="attrs"
                              v-on="on"
                          ></v-text-field>
                        </template>
                        <v-date-picker
                          v-model="formConfirmPayment.payment_date"
                          locale="th-th"
                          @input="menu2 = false"
                        ></v-date-picker>
                      </v-menu>
                        <!-- this.timePickerDialog = false; -->
                  </div>
              </div>
            </div>
        </div>
        <div class="pa-2">
    <div class="d-flex justify-content-between">
      <p class="d-flex justify-end align-items-center" style="width: 30%;">เวลาที่โอนเงิน :</p>
      <div class="ml-5" style="width: 70%;">
        <div style="width: 50%;">
          <v-menu
            v-model="menu_time"
            :close-on-content-click="false"
            :nudge-right="40"
            transition="scale-transition"
            offset-y
            min-width="auto"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                v-model="formConfirmPayment.payment_time"
                outlined
                dense
                append-icon="mdi-clock"
                readonly
                v-bind="attrs"
                v-on="on"
              ></v-text-field>
            </template>
            <v-time-picker
              v-model="formConfirmPayment.payment_time"
              format="24hr"
              @change="menu_time = false"
            ></v-time-picker>
          </v-menu>
        </div>
      </div>
    </div>
  </div>
  <div class="pa-2">
            <div class="d-flex  justify-content-between">
                <p class="d-flex justify-end align-items-center" style="width:30%">ธนาคารที่โอน :</p>
                <v-select
                    class="ml-5"
                    style="width: 70%"
                    v-model="formConfirmPayment.bank"
                    :items="banks"
                    label="เลือกธนาคาร"
                    ></v-select>
            </div>
        </div>
        <div class="pa-2">
        <div class="d-flex  justify-content-between">
            <p class="d-flex justify-end" style="width:30%">หลักฐานการชำระ :</p>
            <div class="ml-5 "
            style="width: 70%">
            <div class="d-flex  align-items-start">
            <img  v-if='imageSlip' class=" fluid " style="width: 150px; height: 150px" :src="`${imageSlip}`" alt="" />
            <label class="" style="width: 15px; height: 15px;cursor: pointer;" for="uploadImage">
            <div v-b-modal.my-modal class="d-flex  ga-10 cursor-pointer align-items-center" style="background-color: gray;  color:white;width:max-content;padding: 4px 8px;">
                <b-icon class="font-title" icon="upload"></b-icon>
                <div class="ml-2">Upload</div>
            </div>
            </label>
            <input id="uploadImage" style="visibility: hidden; width: 0px" accept="image/*" type="file" @change="imageChange"  />
            <!-- <input @click="show = true" id="uploadImage" style="visibility: hidden; width: 0px" /> -->
            </div>
            <div class=" mt-2 " style="font-size: 14px; color:gray;">หมายเหตุ : อัพโหลดไฟล์ .jpg/.png  จำนวน 1 ฉบับ </div>
        </div>
        </div>
    </div>
    <div class="pa-2">
        <div class="d-flex  justify-content-between ">
            <p class="d-flex justify-end" style="width:30%"></p>
            <div class="ml-5 d-flex  " style="width: 70%">
            <!-- <b-button  id="btnSubmit" class="btn main-black-btn mr-5">ลบ</b-button> -->
            <b-button @click="navigateToCourse" id="btnSubmit" class="btn main-black-btn mr-5">ยกเลิก</b-button>
            <b-button @click="submit()" id="btnSubmit" class="btn main-black-btn-active mr-5 ">ยืนยันการชำระเงิน</b-button>
        </div>
    </div>
    </div>

    <!-- <ImageCrop @image="imageSlip = $event; hasImage = 1; " @toggleShow="show = $event" :isShow="show" /> -->

    <!-- <b-modal id="my-modal">Hello From My Modal!</b-modal> -->

        </div>
    </div>
    </div>
  </template>
  
  <script>
  import TopNav from "@/components/topNav.vue";
// import ImageCrop from "@/components/ImageCrop";
import Loading from "@/components/Loading.vue";
import { mapState } from "vuex";
import Swal from "sweetalert2";

  export default {
    components: {
      TopNav,
      // ImageCrop,
      Loading
    },
    data() {
      return {
        menu2: false,
        formConfirmPayment: {
        payment_date: new Date().toISOString().substr(0, 10),
        payment_time: null,
        bank: null, 
        enroll_id:null, 
        subject_id:null, 
        user_id:null, 
        image: null,
        },
        hasImage: "",
        selectedItem: null,
        banks:[
          "กสิกรไทย (KBANK)",
          "กรุงเทพ (BBL)",
          "กรุงไทย (KTB)",
          "กรุงศรี (Krungsri)",
          "ซีไอเอ็มบี (CIMB)",
          "ทหารไทยธนชาต (TTB)",
          "ไทยพาณิชย์ (SCB)",
          "ยูโอบี (UOB)",
          "แลนด์ แอนด์ เฮ้าส์ (LH Bank)",
          "สแตนดาร์ดฯ (SCBT)",
          "ออมสิน (GSB)",
          "เกียรตินาคินภัทร (KKP)",
          "ซิตี้แบงก์ (CITI)",
          "อาคารสงเคราะห์ (GHB)",
          "ธ.ก.ส. (BAAC)",
          "มิซูโฮ (Mizuho Bank)",
          "ธนาคารอิลาม (ibank)",
          "ทิสโก้ (TISCOB)",
          "ไอซีบีซี  (ICBC)",
          "ไทยเครดิต (Thai Credit)",
          "เอชเอสบีซี (HSBC)"],
        show: false,
        isLoading: false,
        imageSlip: null,

        menu_time: false,
      };
  },
   mounted() {
    this.getContent();
  },
   computed: {
    ...mapState({
      course: (state) => {
        return state.Courses.course;
      },userData: (state) => state.Auth.userData,
      access_token: (state) => state.Auth.access_token,
    }),
    computedDateFormatted() {
      return new Date(this.formConfirmPayment.payment_date).toLocaleDateString('th-TH', {
        year: 'numeric',
        month: 'long',
        day: 'numeric'
      });
    },
    computedTimeFormatted() {
      if (!this.formConfirmPayment.payment_time) return '';
      const [hours, minutes] = this.formConfirmPayment.payment_time.split(':');
      return `${hours}:${minutes}`;
    },
    
},
 methods: {
    navigateToCourse() {
      this.$router.push({ name: "allCourses" });
    },
     submit(){
        this.formConfirmPayment.enroll_id = this.course.enroll[0].id;
        this.formConfirmPayment.subject_id = this.course.id;
        this.formConfirmPayment.user_id = this.userData.id;
        this.formConfirmPayment.payment_datetime =  this.formConfirmPayment.payment_date + " " + this.formConfirmPayment.payment_time;
        
        const formData = new FormData();
        formData.append('enroll_id', this.formConfirmPayment.enroll_id);
        formData.append('subject_id', this.formConfirmPayment.subject_id);
        formData.append('user_id', this.formConfirmPayment.user_id);
        formData.append('payment_datetime', this.formConfirmPayment.payment_datetime);
        formData.append('bank', this.formConfirmPayment.bank);
        if (this.imageSlip) {
            formData.append('image', this.imageSlip);
        }  
        for (let [key, value] of formData.entries()) {
          console.log(`${key}: ${value}`);
        }
        (this.isLoading = true),
         this.$store
          .dispatch("Courses/sendPaymentSlip", { 
            data: formData,
            accessToken: this.access_token,})
          .then((response) => {
            console.log(response);

            Swal.close();
            if (response.status == "success") {
              Swal.fire({
                title: 'สำเร็จ',
                text: 'การยืนยันชำระเงินสำเร็จ!',
                icon: 'success',
                confirmButtonText: 'ตกลง'
              }).then(() => {
                this.$router.push({ name: 'courses',
                  query: { id: this.course.id }
                }); 
              });
            }

          })
          .catch((e) => {
            Swal.close();
            if (e.response && e.response.status === 422) {
              // Collect validation error messages
              let errorMessages = '';
              for (let key in e.response.data.errors) {
                errorMessages += `${e.response.data.errors[key].join(' ')}<br>`;
              }

              Swal.fire({
                title: 'ข้อผิดพลาดในการตรวจสอบข้อมูล',
                html: errorMessages,
                icon: 'error',
                confirmButtonText: 'ตกลง'
              });
            } else {
              Swal.fire({
                title: 'ข้อผิดพลาด',
                text: 'การชำระเงินล้มเหลว!',
                icon: 'error',
                confirmButtonText: 'ตกลง'
              });
            }
            console.log("response", e);
          })
          .finally(() => {
            this.isLoading = false;
          });
        },
        async imageChange(e){

          const file = e.target.files[0];
          if (file) {
            const reader = new FileReader();
            reader.readAsDataURL(file);
            reader.onload = () => {
              this.imageSlip = reader.result;
            };
            reader.onerror = (error) => {
              console.error('error : ',error);
              this.imageSlip=null;
              Swal.fire({
                title: 'ข้อผิดพลาด',
                text: 'การเลือกภาพล้มเหลว!',
                icon: 'error',
                confirmButtonText: 'ตกลง'
              });
            };
          }
        },
        async getContent() {
        this.isLoading = true;

          const id = this.$route.params.id;
          if (id) {
            await this.$store.dispatch("Courses/getCourse", {
              id: id,
              token: this.access_token,
              user_id: this.userData.id,
            });
          } else {
            this.$router.push({
              name: "allCourses",
            });
          }
        this.isLoading = false;

        },
      },
    }
  </script>
  
  <style></style>
  